import React from "react"
import styled from "styled-components"
import { rhythm } from "../utils/typography"
import { Link } from "gatsby"

const Reset = styled.div`
  & a {
    text-decoration: none;
    color: inherit;
  }
`
const Container = styled(Reset)`
  max-width: ${({ maxWidth }) => maxWidth || 1000}px;
  margin: 50px auto;
  padding: ${({ hide }) => hide ? 0 : 10}px;
  @media screen and (min-width: 800px) {
    margin: ${({ hide }) => hide ? 0 : 75}px auto;
  }
`
const Menu = styled.nav`
  background-color: #333;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0 ${rhythm(1)};
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5), 0 0 22px 0 rgba(0, 0, 0, 0.3);
  z-index: 10;
`
const MenuItemHeader = styled.h3`
  margin: 0;
  border-top: 3px solid ${({ selected }) => (selected ? "#ccc" : "transparent")};
  padding: 7px 6px 10px 6px;
  color: ${({ selected }) => (selected ? "#eee" : "#999")};
  :hover {
    border-top: 3px solid #777;
  }
`
const Spacer = styled.div`
  width: 100px;
`

const MenuItem = ({ children, to, left, selected }) => (
  <>
    <Link to={to}>
      <MenuItemHeader selected={selected}>{children}</MenuItemHeader>
    </Link>
    <Spacer />
  </>
)

export default class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { at, maxWidth, hide, children } = this.props
    return (
      <>
        <Container maxWidth={maxWidth} hide={hide}>
          <Menu>
            <MenuItem to={"/"} at={at} selected={at === "home"}>
              Home
            </MenuItem>
            <MenuItem to={"/gallery"} at={at} selected={at === "gallery"}>
              Gallery
            </MenuItem>
            <MenuItem to={"/inquire"} at={at} selected={at === "inquire"}>
              Inquire
            </MenuItem>
            <MenuItem to={"/blog"} at={at} selected={at === "blog"}>
              Blog
            </MenuItem>
          </Menu>
          {children}
        </Container>
      </>
    )
  }
}
