import Typography from "typography"

const typography = new Typography({
  googleFonts: [
    {
      name: "Montserrat",
      styles: ["400", "500"],
    },
    {
      name: "Merriweather",
      styles: ["400", "400i", "700", "700i"],
    },
  ],
  scaleRatio: 2,
  bodyColor: "hsla(0, 0%, 0%, 0.75)",
  headerColor: "hsla(0, 0%, 0%, 0.9)",
  baseFontSize: "16px",
  baseLineHeight: 1.666,
  headerFontFamily: ["Montserrat", "sans-serif"],
  bodyFontFamily: ["Merriweather", "serif"],
  headerWeight: "400",
})

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
